(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-registry/assets/javascripts/betslip-button.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-registry/assets/javascripts/betslip-button.js');
"use strict";

const {
  useEffect,
  useRef
} = React;
const {
  useDispatch
} = ReactRedux;
const {
  actions: betslipActions
} = svs.components.tipsen.store.reducers.betslipSlice;
const {
  betslipForceTypes
} = svs.components.sport.tipsenShared;
const BetslipButton = _ref => {
  let {
    isVisible = false
  } = _ref;
  const buttonWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const element = useRef(document.querySelector('.js-pg_menu_portal'));
  useEffect(() => {
    if (isVisible) {
      gsap.to(buttonWrapperRef.current, {
        y: 0,
        ease: 'power1.out',
        duration: 0.2
      });
    } else {
      gsap.to(buttonWrapperRef.current, {
        y: '150%',
        ease: 'power1.out',
        duration: 0.4
      });
    }
  }, [isVisible]);
  if (!element.current) {
    return null;
  }
  return ReactDOM.createPortal(React.createElement("div", {
    className: "pg_betslip__display_button_wrapper",
    ref: buttonWrapperRef,
    style: {
      transform: "translateY(100px)"
    }
  }, React.createElement("button", {
    className: "pg_betslip__display_button pg_betslip__betslip_flag",
    "data-testid": "qa-betslip-betslip-btn",
    onClick: () => {
      dispatch(betslipActions.setBetslipState({
        type: betslipForceTypes.BETSLIP
      }));
    },
    type: "button"
  }, "Betslip")), element.current);
};
setGlobal('svs.components.tipsen.betslipRegistry.BetslipButton', BetslipButton);

 })(window);