(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-registry/assets/javascripts/menu-button.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-registry/assets/javascripts/menu-button.js');
"use strict";

const {
  useDispatch
} = ReactRedux;
const {
  actions: betslipActions
} = svs.components.tipsen.store.reducers.betslipSlice;
const {
  betslipForceTypes
} = svs.components.sport.tipsenShared;
const MenuButton = () => {
  const dispatch = useDispatch();
  return React.createElement("div", {
    className: "pg_betslip__display_button_wrapper"
  }, React.createElement("button", {
    className: "pg_betslip__display_button pg_betslip__menu_flag",
    "data-testid": "qa-betslip-menu-btn",
    onClick: () => {
      dispatch(betslipActions.setBetslipState({
        type: betslipForceTypes.MENU
      }));
    },
    type: "button"
  }, "Meny"));
};
setGlobal('svs.components.tipsen.betslipRegistry.MenuButton', MenuButton);

 })(window);