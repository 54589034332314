(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-registry/assets/javascripts/use-get-betslip-force-type.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-registry/assets/javascripts/use-get-betslip-force-type.js');
"use strict";

const {
  useEffect
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  selectBetslipType,
  actions: betslipActions
} = svs.components.tipsen.store.reducers.betslipSlice;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  betslipForceTypes
} = svs.components.sport.tipsenShared;
const {
  selectCouponSignCount
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const useGetBetslipForceType = () => {
  const couponId = useCouponId();
  const dispatch = useDispatch();
  const isExtraSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const couponSignCount = useSelector(state => selectCouponSignCount(state, couponId));
  const betslipForceType = useSelector(state => selectBetslipType(state));
  useEffect(() => {
    if (couponSignCount > 0) {
      dispatch(betslipActions.setBetslipState({
        type: betslipForceTypes.BETSLIP
      }));
    }
    if (couponSignCount === 0 && !isExtraSmallDevice) {
      dispatch(betslipActions.setBetslipState({
        type: betslipForceTypes.NONE
      }));
    }
    if (couponSignCount === 0 && isExtraSmallDevice) {
      dispatch(betslipActions.setBetslipState({
        type: betslipForceTypes.MENU
      }));
    }
  }, [couponSignCount, dispatch, isExtraSmallDevice]);
  return betslipForceType;
};
setGlobal('svs.components.tipsen.betslipRegistry.useGetBetslipForceType', useGetBetslipForceType);

 })(window);