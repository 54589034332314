(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-registry/assets/javascripts/use-is-scrolling.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-registry/assets/javascripts/use-is-scrolling.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  throttle
} = _;

const useIsScrolling = function () {
  let delayInMS = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
  const [isScrolling, setIsScrolling] = useState(false);
  useEffect(() => {
    let scrollingTimeout;
    let isCurrentlyScrolling;
    const handleScroll = throttle(() => {
      if (!isCurrentlyScrolling) {
        setIsScrolling(true);
        isCurrentlyScrolling = true;
      }
      clearTimeout(scrollingTimeout);
      scrollingTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, delayInMS);
    }, 200);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollingTimeout);
    };
  }, [delayInMS, isScrolling]);
  return isScrolling;
};
setGlobal('svs.components.tipsen.betslipRegistry.useIsScrolling', useIsScrolling);

 })(window);